import { URL_PARAMETERS } from "@/components/ProductListV2/constants";
import type {
  FetchDataClientSideProps,
  UseProductListProps,
} from "@/hooks/useProductList/types";
import { FILTER_SEPARATOR } from "@/react-app/constants";
import { useSharedData } from "@/react-app/contexts/SharedData";
import type { XXLCookie } from "@/react-app/global";
import { useSession } from "@/react-app/hooks/useSession";
import {
  cookieNames,
  getCustomerKey,
  getFallbackCustomerKey,
  getPreferredStoresCookie,
  getSessionKey,
} from "@/react-utils/Cookie";
import { fetchProductList } from "@/utils/apis/api-access";
import type { BrandProductListResponseData } from "@/utils/apis/product-search/elevate/brand/brand-product-list";
import type { CampaignProductListResponseData } from "@/utils/apis/product-search/elevate/campaign/campaign-product-list";
import type { CategoryProductListResponseData } from "@/utils/apis/product-search/elevate/category/category-product-list";
import { hasNoValue, hasValue } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useState } from "react";

let latestRequestId = 0;
const generateLatestRequestId = () => (latestRequestId = latestRequestId + 1);

const useProductList = (props: UseProductListProps) => {
  const {
    api,
    facetsFromServer,
    numberOfProductsPerPage,
    pageType,
    productsFromServer,
    stores,
    subType,
    totalHitsFromServer,
    isTeamsales,
  } = props;
  const campaignId = "campaignId" in props ? props.campaignId : null;
  const longTailFacets =
    "longTailFacets" in props ? props.longTailFacets : null;
  const longTailPattern =
    "longTailPattern" in props ? props.longTailPattern : null;
  const additionalCampaignIds =
    subType === "campaign" ? props.additionalCampaignIds : null;
  const brandCode = subType === "brand" ? props.brandCode : null;

  const {
    featureToggles: {
      toggle_elevate_cluster_landing_page,
      toggle_force_member_price_display,
    },
    siteUid,
  } = useSharedData().data;
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const [products, setProducts] = useState(productsFromServer);
  const [facets, setFacets] = useState(facetsFromServer);
  const [totalHits, setTotalHits] = useState(totalHitsFromServer);
  const [isFetchingProducts, setIsFetchingProducts] = useState(true);

  useEffect(() => {
    setFacets(facetsFromServer);
  }, [facetsFromServer]);

  useEffect(() => {
    setProducts(productsFromServer);
  }, [productsFromServer]);

  useEffect(() => {
    setTotalHits(totalHitsFromServer);
  }, [totalHitsFromServer]);

  const fetchDataClientSide = useCallback(
    ({
      availability,
      categoryId,
      page,
      selectedFilters,
      selectedSort,
      selectedStores,
    }: FetchDataClientSideProps) => {
      const onResponse = (
        data:
          | BrandProductListResponseData
          | CampaignProductListResponseData
          | CategoryProductListResponseData
          | null
      ) => {
        const requestId = generateLatestRequestId();

        if (requestId !== latestRequestId) {
          return;
        }

        if (data === null) {
          throw Error(
            `No data available. Verify that correct subType is used. Current subType: ${subType}`
          );
        }

        setIsFetchingProducts(false);

        setFacets((state) => {
          if (isEqual(state, data.facets)) {
            return state;
          }
          return data.facets;
        });

        setProducts((state) => {
          if (isEqual(state, data.products)) {
            return state;
          }
          return data.products;
        });

        setTotalHits(data.totalHits);
      };

      void (async () => {
        try {
          setIsFetchingProducts(true);
          const customerKey = getCustomerKey() ?? getFallbackCustomerKey();
          const sessionKey = getSessionKey();

          const fakeXXLCookie: XXLCookie = { loggedIn: isLoggedIn };
          const commonArgs = {
            cookies: {
              [cookieNames.PREFERRED_STORES]: JSON.stringify(
                getPreferredStoresCookie()
              ),
              [cookieNames.XXL]: JSON.stringify(fakeXXLCookie),
            },
            headers: {},
            numberOfProductsPerPage,
            pageType,
            query: {
              [URL_PARAMETERS.availability.name]:
                `[${availability.map((a) => `"${a}"`).toString()}]`,
              ...Object.fromEntries(
                Object.entries(selectedFilters).map(([key, value]) => [
                  `${URL_PARAMETERS.facet.name}${key}`,
                  value.join(FILTER_SEPARATOR),
                ])
              ),
              [URL_PARAMETERS.page.name]: page.toString(),
              [URL_PARAMETERS.sort.name]: selectedSort,
              [URL_PARAMETERS.selectedStores.name]:
                `[${selectedStores.map((s) => `"${s}"`).toString()}]`,
            },
            siteUid,
            subType,
            stores,
            toggle_elevate_cluster_landing_page,
            toggle_force_member_price_display,
            userKeys: { customerKey, sessionKey },
          } as const;

          const data = null;

          if (subType === "category") {
            if (hasNoValue(categoryId)) {
              throw Error("Expecting categoryId to be defined");
            }
            // Selected facets are listed in commonArgs.query
            const longTailFacetsWithoutValues =
              longTailFacets?.map((longTailFacet) => ({
                ...longTailFacet,
                values: [],
              })) ?? null;
            const categoryData = await fetchProductList.category({
              ...commonArgs,
              categoryId,
              options: {
                usage: "client-side",
                apiFunction: api.storefront.landingPage,
              },
              longTailFacets: longTailFacetsWithoutValues,
              longTailPattern,
              query: {
                ...commonArgs.query,
              },
              isTeamsales,
            });

            onResponse(categoryData);
          }

          if (
            subType === "campaign" &&
            hasValue(campaignId) &&
            additionalCampaignIds !== null
          ) {
            const campaignData = await fetchProductList.campaign({
              ...commonArgs,
              options: {
                usage: "client-side",
                apiFunction: api.storefront.landingPage,
              },
              campaignId,
              additionalCampaignIds,
              ...(hasValue(categoryId) && {
                categoryIds: [categoryId],
              }),
              isTeamsales,
            });

            return onResponse(campaignData);
          }

          if (brandCode !== null) {
            const brandData = await fetchProductList.brand({
              ...commonArgs,
              options: {
                usage: "client-side",
                apiFunction: api.storefront.landingPage,
              },
              brandCode,
              isTeamsales,
              toggle_force_member_price_display,
            });

            return onResponse(brandData);
          }

          return onResponse(data);
        } catch (error) {
          setIsFetchingProducts(false);
          log.error(error);
        }
      })();
      return null;
    },
    [
      additionalCampaignIds,
      api.storefront.landingPage,
      brandCode,
      campaignId,
      isLoggedIn,
      isTeamsales,
      longTailFacets,
      longTailPattern,
      numberOfProductsPerPage,
      pageType,
      siteUid,
      stores,
      subType,
      toggle_elevate_cluster_landing_page,
      toggle_force_member_price_display,
    ]
  );

  return {
    facets,
    fetchDataClientSide,
    isFetchingProducts,
    products,
    longTailFacets,
    longTailPattern,
    totalHits,
  };
};

export { useProductList };
